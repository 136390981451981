import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore} from "@angular/fire/firestore";
import {Router} from "@angular/router";

@Component({
  selector: 'app-console-login',
  templateUrl: './console-login.component.html',
  styleUrls: ['./console-login.component.scss']
})
export class ConsoleLoginComponent implements OnInit {

  error: string = "";
  email: string = "";
  password: string = "";
  admins = ["1GLUIIPVPnZO97osRKiqaI5BWfB3", "vic1NcbJe3azxrMqSS8u077PtXV2", "a17WjLAGgZZw1vgqxxX31Ot7CbI2"];

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private router: Router) { }

  ngOnInit() {
    if (this.afAuth.auth.currentUser == null){
      console.log("Not logged in")
    }else{
      console.log("Logged in");
      this.checkAdmin()
    }
  }
  
  signIn(){
    if (this.email.length > 0 && this.password.length > 0){
      this.afAuth.auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(value => {
          console.log('login');
          this.checkAdmin();
        })
        .catch(err =>{
          this.error = err.message;
        })
    }else{
      this.error = "Please fill out all fields"
    }
  }

  checkAdmin(){
    if (this.admins.includes(this.afAuth.auth.currentUser.uid)){
      console.log("isAdmin");
      this.router.navigate(['/console/home']);
    }else{
      this.afAuth.auth.signOut();
      this.error = "Must be an Admin"
    }
  }
}
