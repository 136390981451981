import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {TermsComponent} from "./terms/terms.component";
import {AboutComponent} from "./about/about.component";
import {ContactComponent} from "./contact/contact.component";
import {ConsoleLoginComponent} from "./console-login/console-login.component";
import {ConsoleHomeComponent} from "./console-home/console-home.component";
import {ConsoleAutoEventsComponent} from "./console-auto-events/console-auto-events.component";
import {ConsoleAddAutoEventComponent} from "./console-add-auto-event/console-add-auto-event.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'terms-of-service', component: TermsComponent},
  {path: 'console', redirectTo:'/console/login', pathMatch: 'full'},
  {path: 'console' ,children:[
      {path: 'login', component: ConsoleLoginComponent},
      {path: 'home', component: ConsoleHomeComponent},
      {path: 'auto-events', component: ConsoleAutoEventsComponent},
      {path: 'add-auto-event', component: ConsoleAddAutoEventComponent}
    ]},
  {path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
