import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/firestore";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {Event} from "../event";

@Component({
  selector: 'app-console-auto-events',
  templateUrl: './console-auto-events.component.html',
  styleUrls: ['./console-auto-events.component.scss']
})
export class ConsoleAutoEventsComponent implements OnInit {

  searchCriteria: String = "";
  eventDocs: AngularFirestoreCollection<Event>;
  events: Observable<Event[]>;

  constructor(private db: AngularFirestore, private router: Router) { }

  ngOnInit() {
    this.eventDocs = this.db.collection('auto-events');
    this.events = this.eventDocs.valueChanges();
  }

}
