import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MapsAPILoader} from "@agm/core";
import {HttpClient} from "@angular/common/http";
import {google} from 'google-maps'
import {AngularFirestore} from "@angular/fire/firestore";
import {Router} from "@angular/router";
import {forEach} from "@angular/router/src/utils/collection";

@Component({
  selector: 'app-console-add-auto-event',
  templateUrl: './console-add-auto-event.component.html',
  styleUrls: ['./console-add-auto-event.component.scss']
})
export class ConsoleAddAutoEventComponent implements OnInit {
  public latitude: number = 32.5933619;
  public longitude: number = -85.497355;
  public searchControl: FormControl;
  public zoom: number = 4;
  public name: string = "";
  public website: string = "";
  public sunday: boolean = false;
  public monday: boolean = false;
  public tuesday: boolean = false;
  public wednesday: boolean = false;
  public thursday: boolean = false;
  public friday: boolean = false;
  public saturday: boolean = false;
  public placeId: string = "";
  public photoURL: string = "";
  public cityId: string = "";

  @ViewChild("search")
  public searchElementRef: ElementRef;

  constructor( private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private http: HttpClient, private db: AngularFirestore, private router: Router) { }

  ngOnInit() {

    this.searchControl = new FormControl();

    this.setCurrentPosition();

    this.mapsAPILoader.load().then(() =>{
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {

      });
      autocomplete.addListener("place_changed", () =>{
        this.ngZone.run(()=>{
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry == null){
            return;
          }

          this.sunday = false;
          this.monday = false;
          this.tuesday = false;
          this.wednesday = false;
          this.thursday = false;
          this.friday = false;
          this.saturday = false;

          this.placeId = place.place_id;
          this.name = place.name;
          this.website = place.website;

          console.log(place.address_components);

          var city = "";
          var state = "";
          var country = "";
          place.address_components.forEach(c =>{
            c.types.forEach(t => {
              if (t == "locality"){
                city = c.long_name;
              } 
              if (t == "administrative_area_level_1") {
                state = c.long_name
              }
              if (t == "country") {
                country = c.long_name
              }
            }) 
          });

          this.cityId = city + "@" + state + "@" + country;

          if (place.photos.length > 0){
            //console.log(place.photos[0]);
            this.photoURL = place.photos[0].getUrl({'maxWidth' : 1024, 'maxHeight' : 1024})
          }


          //console.log(place.opening_hours);
          place.opening_hours.periods.forEach(p =>{
            console.log(p);
            switch (p.open.day) {
              case 0: this.sunday = true; break;
              case 1: this.monday = true; break;
              case 2: this.tuesday = true; break;
              case 3: this.wednesday = true; break;
              case 4: this.thursday = true; break;
              case 5: this.friday = true; break;
              case 6: this.saturday = true; break;
            }
          });

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 17;
        })
      })
    })

  }

  private setCurrentPosition(){
    if("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12
      })
    }
  }

  addEvent(){
    if (this.placeId){

      if (this.website){

      } else{
        this.website = "";
      }

      this.db.collection('auto-events').doc(this.placeId).set({"Lat" : this.latitude, "Long" : this.longitude, "Name" : this.name, "PlaceID" : this.placeId,"CityID" : this.cityId, "Website": this.website,
                                                                    "Mon": this.monday, "Tue" : this.tuesday, "Wed" : this.wednesday, "Thu": this.thursday, "Fri" : this.friday, "Sat" : this.saturday, "Sun" : this.sunday});
      this.router.navigate(['/console/auto-events'])
    }
  }

}
