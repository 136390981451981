import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-console-home',
  templateUrl: './console-home.component.html',
  styleUrls: ['./console-home.component.scss']
})
export class ConsoleHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
