import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./footer/footer.component";
import { AppComponent } from "./app/app.component";
import { TermsComponent } from './terms/terms.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule} from "@angular/common/http";
import { AgmCoreModule } from "@agm/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ConsoleLoginComponent } from './console-login/console-login.component';
import { AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../environments/environment";
import { ConsoleHomeComponent } from './console-home/console-home.component';
import { ConsoleSidebarComponent } from './console-sidebar/console-sidebar.component';
import { ConsoleAutoEventsComponent } from './console-auto-events/console-auto-events.component';
import { ConsoleAddAutoEventComponent } from './console-add-auto-event/console-add-auto-event.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    TermsComponent,
    AboutComponent,
    ContactComponent,
    ConsoleLoginComponent,
    ConsoleHomeComponent,
    ConsoleSidebarComponent,
    ConsoleAutoEventsComponent,
    ConsoleAddAutoEventComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAL8fuCx8aVEaR4kEDiVY9D9t6BI4kMSwA',
      libraries: ['geometry', 'places']
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
