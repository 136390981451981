import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore} from "@angular/fire/firestore";
import {Router} from "@angular/router";

@Component({
  selector: 'app-console-sidebar',
  templateUrl: './console-sidebar.component.html',
  styleUrls: ['./console-sidebar.component.scss']
})
export class ConsoleSidebarComponent implements OnInit {
  admins = ["1GLUIIPVPnZO97osRKiqaI5BWfB3", "vic1NcbJe3azxrMqSS8u077PtXV2", "a17WjLAGgZZw1vgqxxX31Ot7CbI2"];

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private router: Router) { }

  ngOnInit() {
    if (this.afAuth.auth.currentUser == null){
      console.log("Not logged in");
      this.router.navigate(['/console/login']);
    }else{
      console.log("Logged in");
      this.checkAdmin()
    }
  }

  checkAdmin(){
    if (this.admins.includes(this.afAuth.auth.currentUser.uid)){
      console.log("isAdmin");
      //this.router.navigate(['/console/home']);
    }else{
      this.afAuth.auth.signOut();
      this.router.navigate(['/console/login']);
    }
  }

}
